<template>
    <div class="bg-white rounded-standard shadow-standard animate-pulse">
        <!-- order header -->
        <div class="flex justify-between px-6 py-4">
            <div class="flex-none flex flex-row items-center space-x-4">
                <button class="flex-none flex items-center justify-center shadow-standard rounded-full w-10 h-10 border border-cultured border-solid focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transition-transform duration-500 ease-in-out" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                <div class="w-32 sm:w-40 lg:w-60">
                    <div class="h-6 bg-gray-400 rounded-standard"></div>
                </div>
            </div>
            <div>
                <div class="flex flex-col items-end space-y-2">
                    <span class="text-white inline-flex text-sm font-bold bg-gray-400 flex-none px-5 w-20 sm:w-24 h-8 py-1 rounded-2xl uppercase tracking-wide"></span>
                    <span class="h-4 bg-gray-400 rounded-standard w-8 sm:w-12"></span>
                </div>
            </div>
        </div>
        <!-- end order header -->
    </div>
</template>

<script lang="ts">
export default {
    name: 'OrderPillSkeleton',
    setup() {
        return {
        }
    },
}
</script>