
import { ref } from 'vue'
import moment from 'moment'
import OrderDetails from './OrderDetails.vue'
import CopyButton from './CopyButton.vue'

export default {
    name: 'OrderPill',
    components: {
        OrderDetails,
        CopyButton,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const open = ref(false)
        const init = ref(false)
        const ctrlDetails = ref()

        const statusText = ref('')
        const statusClass = ref('text-silver-metallic')

        let orderType = 'Delivery'
        let orderTypeClass = 'bg-purpureus'

        switch(props.record.orderStatus) { 
            case 'OrderCompleted': { 
                statusText.value = 'Completed'
                statusClass.value = 'text-silver-metallic'
                break; 
            } 
            case 'OrderAccepted': { 
                statusText.value = 'Accepted'
                statusClass.value = 'text-mountain-meadow'
                break; 
            }
            case 'OrderDeclined': { 
                statusText.value = 'Declined'
                statusClass.value = 'text-peach'
                break; 
            }
            case 'OrderConfirmed': { 
                statusText.value = 'Confirmed'
                statusClass.value = 'text-peach'
                break; 
            }
        }

        switch(props.record.orderType) { 
            case 1: { 
                orderType = 'Pickup'
                orderTypeClass = 'bg-yellow-orange'
                break; 
            } 
            case 2: { 
                orderType = 'Dine-In'
                orderTypeClass = 'bg-mountain-meadow'
                break; 
            }
        }

        const orderOnText = moment(props.record.orderedOn).fromNow()

        const loadDetails = () => {
            if (!open.value)
            {
                open.value = true
                ctrlDetails.value.load(props.record.orderID)
            }
            else
                open.value = false
        }

        const fromConfirmToDecline = () => {
            statusText.value = 'Declined'
            statusClass.value = 'text-peach'
        }

        return {
            open,
            init,
            statusText,
            statusClass,
            orderType,
            orderTypeClass,
            orderOnText,
            ctrlDetails,
            loadDetails,
            fromConfirmToDecline,
        }
    },
}
