
import { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import fUI from '../integration/foodexUI'

export default defineComponent({
    name: fUI.ID.InvoiceModal,
    components: { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, },
    props: {
        details: { type: Object, required: true },
    },
    setup() {
        const amount = ref(0)
        const msg = ref('')

        const onSucces = () => {
            fUI.modalSuccess(fUI.ID.InvoiceModal, {
                success: true,
                amount: amount.value,
                msg: msg.value,
            })
        }

        const updateValue = (event: any) => {
            const value = event.target.value
            amount.value = 0
            if (value)
            {
                amount.value = +value >= 100 ? 100 : +(+value).toFixed(2)
            }
        }
        
        return {
            amount,
            msg,
            onDismiss: () => fUI.modalCancel(fUI.ID.InvoiceModal),
            onSucces,
            updateValue,
        }
    },
})
