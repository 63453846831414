<template>
    <div class="bg-white rounded-standard shadow-standard">
        <!-- order header -->
        <div class="flex justify-between px-6 py-4">
            <div class="flex-none flex flex-row items-center space-x-4">
                <button @click="loadDetails()" class="flex-none flex items-center justify-center shadow-standard rounded-full w-10 h-10 border border-cultured border-solid focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transition-transform duration-500 ease-in-out" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="!open && 'transform rotate-180'">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                <div class="flex flex-col space-y-0">
                    <div class="flex items-center">
                        <label class="font-bold text-2xl">#{{record.orderShortID}}</label>
                        <copy-button class="ml-1 text-gray-600" :content="record.orderID"></copy-button>
                    </div>
                    
                    <label class="uppercase text-sm font-bold tracking-wide" :class="statusClass">{{statusText}}</label>
                </div>
            </div>
            <div>
                <div class="flex flex-col items-end space-y-2">
                    <span class="w-28 text-white text-center text-xs sm:text-sm font-bold flex-none px-5 py-1 rounded-2xl uppercase tracking-wide" :class="orderTypeClass">{{orderType}}</span>
                    <span class="text-silver-metallic text-sm"><span class="hidden sm:inline">Ordered </span>{{orderOnText}}</span>
                </div>
            </div>
        </div>
        <!-- end order header -->

        <order-details ref="ctrlDetails" :class="!open && 'hidden'" :status="record.orderStatus" v-on:statusChange="fromConfirmToDecline()"></order-details>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import OrderDetails from './OrderDetails.vue'
import CopyButton from './CopyButton.vue'

export default {
    name: 'OrderPill',
    components: {
        OrderDetails,
        CopyButton,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const open = ref(false)
        const init = ref(false)
        const ctrlDetails = ref()

        const statusText = ref('')
        const statusClass = ref('text-silver-metallic')

        let orderType = 'Delivery'
        let orderTypeClass = 'bg-purpureus'

        switch(props.record.orderStatus) { 
            case 'OrderCompleted': { 
                statusText.value = 'Completed'
                statusClass.value = 'text-silver-metallic'
                break; 
            } 
            case 'OrderAccepted': { 
                statusText.value = 'Accepted'
                statusClass.value = 'text-mountain-meadow'
                break; 
            }
            case 'OrderDeclined': { 
                statusText.value = 'Declined'
                statusClass.value = 'text-peach'
                break; 
            }
            case 'OrderConfirmed': { 
                statusText.value = 'Confirmed'
                statusClass.value = 'text-peach'
                break; 
            }
        }

        switch(props.record.orderType) { 
            case 1: { 
                orderType = 'Pickup'
                orderTypeClass = 'bg-yellow-orange'
                break; 
            } 
            case 2: { 
                orderType = 'Dine-In'
                orderTypeClass = 'bg-mountain-meadow'
                break; 
            }
        }

        const orderOnText = moment(props.record.orderedOn).fromNow()

        const loadDetails = () => {
            if (!open.value)
            {
                open.value = true
                ctrlDetails.value.load(props.record.orderID)
            }
            else
                open.value = false
        }

        const fromConfirmToDecline = () => {
            statusText.value = 'Declined'
            statusClass.value = 'text-peach'
        }

        return {
            open,
            init,
            statusText,
            statusClass,
            orderType,
            orderTypeClass,
            orderOnText,
            ctrlDetails,
            loadDetails,
            fromConfirmToDecline,
        }
    },
}
</script>