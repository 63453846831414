
import { ref } from 'vue'
export default {
    name: 'CopyButton',
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    setup() {
        const popover = ref(false)
        const canCopy = !!window.navigator.clipboard
        const copyInfo = (info: string) => {
            window.navigator.clipboard.writeText(info)
            popover.value = true
            setTimeout(() => {
                popover.value = false
            }, 2500);
        }

        return {
            canCopy,
            popover,
            copyInfo,
        }
    },
}
