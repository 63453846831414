
import { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import fUI from '../integration/foodexUI'

export default defineComponent({
    name: fUI.ID.DeclineModal,
    components: { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, },
    props: {
        details: { type: Object, required: true },
    },
    setup() {
        const reason = ref('temporarily shutdown')

        const onSucces = () => {
            fUI.modalSuccess(fUI.ID.DeclineModal, {
                success: true,
                reason: reason.value
            })
        }

        return {
            reason,
            onDismiss: () => fUI.modalCancel(fUI.ID.DeclineModal),
            onSucces,
        }
    },
})
