import { toastController, modalController, } from '@ionic/vue'

export default {
    async toasty(msg: string) {
        const toast = await toastController.create({
          message: msg,
          duration: 2000,
        });
        toast.present();
    },
    createModal(opts: any): Promise<HTMLIonModalElement>
    {
        opts.swipeToClose = true
        return modalController.create(opts)
    },
    modalCancel(id: string) {
        modalController.dismiss({},'failed', id)
    },
    modalSuccess(id: string, returnObj: any = {}) {
        modalController.dismiss(returnObj,'accept', id)
    },
    ID: {
        DeclineModal: 'DeclineModal',
        InvoiceModal: 'InvoiceModal',
        RefundModal: 'RefundModal',
    }
}