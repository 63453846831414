<template>
    <div v-if="!init" class="border-t border-solid border-cultured px-4 sm:px-8 grid grid-cols-1 animate-pulse">
        <div class="flex flex-col sm:flex-row justify-between items-center border-t border-solid border-cultured py-4 lg:py-8">
            <div class="h-6 bg-gray-400 rounded-standard w-28 lg:w-52"></div>
            
            <div class="h-6 bg-gray-400 rounded-standard w-28 hidden lg:block"></div>
        </div>

        <div class="grid grid-cols-1 gap-7 border-t border-solid border-cultured py-6 lg:py-8">

            <div class="flex justify-between items-center">
                <div class="flex-none flex items-center space-x-7">
                    <div class="relative">
                        <div class="relative z-0">
                            <div class="w-8 h-8 lg:w-12 lg:h-12 rounded-lg bg-gray-400"></div>
                        </div>
                    </div>
                    <div class="h-4 bg-gray-400 rounded-standard w-20"></div>
                </div>
                <div class="h-4 bg-gray-400 rounded-standard w-12"></div>
            </div>

            <div class="flex justify-between items-center">
                <div class="flex-none flex items-center space-x-7">
                    <div class="relative">
                        <div class="relative z-0">
                            <div class="w-8 h-8 lg:w-12 lg:h-12 rounded-lg bg-gray-400"></div>
                        </div>
                    </div>
                    <div class="h-4 bg-gray-400 rounded-standard w-20"></div>
                </div>
                <div class="h-4 bg-gray-400 rounded-standard w-12"></div>
            </div>

        </div>

        <div class="border-t border-solid border-cultured py-7">
            <div class="grid grid-1 gap-3">
                <div class="flex justify-between items-center">
                    <div class="h-4 bg-gray-400 rounded-standard w-28 lg:w-52"></div>
                    <div class="h-4 bg-gray-400 rounded-standard w-16"></div>
                </div>
                <div class="flex justify-between items-center">
                    <div class="h-4 bg-gray-400 rounded-standard w-20 lg:w-40"></div>
                    <div class="h-4 bg-gray-400 rounded-standard w-12"></div>
                </div>
                <div class="flex justify-between items-center">
                    <div class="h-4 bg-gray-400 rounded-standard w-16 lg:w-36"></div>
                    <div class="h-4 bg-gray-400 rounded-standard w-12"></div>
                </div>
            </div>
        </div>

        <div class="border-t border-solid border-cultured py-7">
            <div class="flex justify-between items-center">
                <div class="h-4 bg-gray-400 rounded-standard w-20 lg:w-40"></div>
                <div class="h-4 bg-gray-400 rounded-standard w-12"></div>
            </div>
        </div>
    </div>
    <div v-else class="border-t border-solid border-cultured px-4 sm:px-8 grid grid-cols-1">
        
        <!-- fufill row-->
        <div class="flex space-x-4 py-4 lg:py-8">
            
            <div v-if="status !== 'OrderDeclined'" class="flex-none">
                <button :disabled="operating && 'true'" class="btn btn-primary text-xs sm:text-sm font-bold uppercase tracking-wide" @click="decline()">
                    Decline
                </button>
            </div>
            <div v-if="status !== 'OrderConfirmed' && status !== 'OrderDeclined'" class="flex-none">
                <button class="btn btn-primary text-xs sm:text-sm font-bold uppercase tracking-wide" @click="refund()">
                    Refund
                </button>
            </div>
            <div v-if="status !== 'OrderConfirmed'" class="flex-none">
                <button class="btn btn-primary text-xs sm:text-sm font-bold uppercase tracking-wide" @click="charge()">
                    Extra Payment
                </button>
            </div>

        </div>
        <!-- end fufill row-->

        <!-- Shopinfo row-->
        <div v-if="details.shop" class="flex flex-col justify-between sm:flex-row items-center sm:items-start text-black-coral-lighter py-4 lg:py-8 border-t border-solid border-cultured">
            <div class="text-center sm:text-left">
                <h2 class="text-xl uppercase font-bold tracking-wide">{{details.shop.name}}</h2>
                <a :href="'tel:' + details.shop.phone">{{$filters.phonify(details.shop.phone)}}</a>
            </div>
            <h2 class="uppercase font-bold tracking-wide text-black-coral-lighter">Fulfill at {{readyByText}}</h2>
            
        </div>
        <!-- end Shopinfo row-->

        <!-- delivery row-->
        <div v-if="details.orderType === 0" class="flex flex-col sm:flex-row justify-between items-center border-t border-solid border-cultured py-4 lg:py-8">
            <h2 class="text-xl font-bold">Destination</h2>
            <div class="flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <a :href="'http://maps.google.com/?daddr=' + details.fullAddress" target="_blank">{{details.fullAddress}}</a>
            </div>
        </div>
        <!-- end delivery row-->

        <!-- user row -->
        <div class="flex flex-col sm:flex-row justify-between items-center border-t border-solid border-cultured py-4 lg:py-8">
            <div class="flex-none flex items-center">
                <h2 class="text-xl font-bold">{{details.customerName}}</h2>
            </div>
            
            <div class="flex-none flex items-center space-x-1 text-black-coral-lighter">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <a :href="'tel:' + details.phoneName">{{$filters.phonify(details.phoneName)}}</a>
                <copy-button class="text-gray-600" :content="details.phoneName"></copy-button>
            </div>
        </div>
        <!-- end user row -->
        
        <!-- menu items -->
        <div v-if="!showDetails" class="border-t border-solid border-cultured py-6 lg:py-8 text-center" @click="showDetails = !showDetails">
            <button type="button" class="w-full sm:w-1/2 btn font-lg text-black-coral border border-solid border-black-coral rounded-2xl">Show Content</button>
        </div>
        <div v-else class="grid grid-cols-1 gap-7 border-t border-solid border-cultured py-6 lg:py-8">
            
            <!-- one menu item row -->
            <div class="space-y-4 lg:space-y-6" v-for="o in orderItems" :key="o.iid">
                <div class="flex justify-between items-start">
                    <div class="relative">
                        <div class="relative z-0">
                            <div class="w-8 h-8 lg:w-12 lg:h-12 flex items-center justify-center rounded-lg border border-solid border-cultured text-black-coral-lighter">
                                <i class="far fa-utensils-alt"></i>
                            </div>
                        </div>
                        <span class="rounded-full inline-flex items-center justify-center font-bold text-xs bg-white shadow-standard w-5 h-5 absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2  border border-solid border-cultured">
                            {{o.quantity}}
                        </span>
                    </div>
                    <div class="flex-grow flex flex-col sm:flex-row justify-between sm:space-x-7 text-black-coral-lighter ml-4 sm:ml-8">
                        <div>{{o.name}}</div>
                        <span>{{$filters.currency(o.subTotal)}}</span>
                    </div>
                </div>

                <div v-if="o.options" class="px-2">
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-cultured" />
                        </div>
                        <div class="relative flex justify-center">
                            <span class="px-2 bg-white text-sm text-gray-600 font-bold uppercase tracking-wide">
                                Options
                            </span>
                        </div>
                    </div>
                    <div class="w-full text-gray-600 pt-3 text-sm flex flex-col gap-1">
                        <!-- this is the list of customizations -->
                        <span class="w-full text-center" v-for="io in o.options" :key="io.oid">{{io.name}}</span>
                    </div>
                </div>
            </div>
            <!-- end menu item row -->

        </div>
        <!-- end menu items -->

        
        <!-- start special request -->
        <div v-if="details.remark" class="px-2">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-cultured" />
                </div>
                <div class="relative flex justify-center">
                    <span class="px-2 bg-white text-sm text-gray-600 font-bold uppercase tracking-wide">
                        Special Requests
                    </span>
                </div>
            </div>
            <p class="w-full text-gray-600 pt-3">
                {{details.remark}}
            </p>
        </div>
        <!-- end special request -->

        <!-- start order totals-->
        <div class="border-t border-solid border-cultured py-7">
            <div class="grid grid-1 gap-3">
                <div class="flex justify-between items-center">
                    <div class="text-silver-metallic">
                        Subtotal
                    </div>
                    <div class="text-black-coral-lighter">
                        {{$filters.currency(details.subTotal)}}
                    </div>
                </div>
                <div v-if="details.discount > 0" class="flex justify-between items-center">
                    <div class="text-silver-metallic">
                        Discount
                    </div>
                    <div class="text-black-coral-lighter">
                        {{$filters.currency(details.discount)}}
                    </div>
                </div>
                <div class="flex justify-between items-center">
                    <div class="text-silver-metallic">
                        Taxes
                    </div>
                    <div class="text-black-coral-lighter">
                        {{$filters.currency(details.gst + details.pst)}}
                    </div>
                </div>
                <div class="flex justify-between items-center">
                    <div class="text-silver-metallic">
                        Tip
                    </div>
                    <div class="text-black-coral-lighter">
                        {{$filters.currency(details.orderTip)}}
                    </div>
                </div>
                <div v-if="details.deliveryFee > 0" class="flex justify-between items-center">
                    <div class="text-silver-metallic">
                        Delivery Fee
                    </div>
                    <div class="text-black-coral-lighter">
                        {{$filters.currency(details.deliveryFee)}}
                    </div>
                </div>
                <div v-if="details.smallOrderFee > 0" class="flex justify-between items-center">
                    <div class="text-silver-metallic">
                        Small Order Fee
                    </div>
                    <div class="text-black-coral-lighter">
                        {{$filters.currency(details.smallOrderFee)}}
                    </div>
                </div>
            </div>
        </div>

        <!-- end order totals -->
            
        <!-- start summary -->
        <div class="border-t border-solid border-cultured py-7">
            <div class="flex justify-between items-center">
                <div class="font-bold text-black-coral">
                    Order Total
                </div>
                <div class="text-black-coral-lighter font-bold text-xl">
                    {{$filters.currency(details.grandTotal)}}
                </div>
            </div>
        </div>
        <!-- end the summary -->
    </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import fUI from '../integration/foodexUI'
import fAPI from '../integration/foodexAPI'
import CopyButton from './CopyButton.vue'
import DeclineModal from '../modals/DeclineModal.vue'
import RefundModal from '../modals/RefundModal.vue'
import InvoiceModal from '../modals/InvoiceModal.vue'
import { AxiosError } from 'axios'
import moment from 'moment'

export default {
    name: 'OrderDetails',
    components: {
        CopyButton,
    },
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    setup(props: any, context: any) {
        const init = ref(false)
        const operating = ref(false)

        const showDeclineBtn = ref(props.status == 'OrderConfirmed')

        let OID = ''
        const details = ref()
        const orderItems = ref([])
        const showDetails = ref(false)
        const userInit = ref(false)
        const readyByText = ref('')

        const load = (orderID: any) => {
            if (!init.value)
            {
                OID = orderID
                fAPI.getOrderReceipt(orderID).then((response)=>{
                    console.log('getting this back', response.data)
                    details.value = response.data
                    orderItems.value = JSON.parse(response.data.receiptContent)
                    readyByText.value = moment(response.data.readyBy).format('h:mm A')
                })
                .catch((authError: AxiosError) => {
                    if (authError && authError.response)
                    {
                        console.log('Operation failed', authError.response.status)
                        console.log('Error', authError.response)
                    }
                })
                .finally(() => {
                    init.value = true
                })
            }
        }

        const decline = async () => {
            operating.value = true

            const modal = await fUI.createModal({
                id: fUI.ID.DeclineModal,
                component: DeclineModal,
                componentProps: {
                    details: details.value
                },
            })

            modal.onDidDismiss().then(({data}) => {
                if (data && data.success) // popover should return true if verification is successful
                {
                    console.log('from modal', data.reason)
                    context.emit('statusChange')
                    showDeclineBtn.value = false
                    fAPI.declineOrder(OID, data.reason).then(()=>{
                        //console.log('getting this back', response.data)
                        fUI.toasty('Decline success!')
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                        }
                    })
                    .finally(() => {
                        operating.value = false
                    })
                }
                else
                    operating.value = true
            })

            await modal.present();
        }

        const refund = async () => {
            operating.value = true
            const modal = await fUI.createModal({
                    id: fUI.ID.RefundModal,
                    component: RefundModal,
                    componentProps: {
                        details: details.value
                    },
                })

            modal.onDidDismiss().then(({data}) => {
                if (data && data.success) // popover should return true if verification is successful
                {
                    //console.log('from modal', data.amount, data.msg, data.source)
                    fAPI.refundOrder(OID, data.amount, data.msg, data.source).then(()=>{
                        //console.log('getting this back', response.data)
                        fUI.toasty('Refund success!')
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                        }
                    })
                    .finally(() => {
                        operating.value = false
                    })
                }
                else
                    operating.value = true
            });
            
            await modal.present();
            operating.value = false
        }

        const charge = async () => {
            operating.value = true
            const modal = await fUI.createModal({
                id: fUI.ID.InvoiceModal,
                component: InvoiceModal,
                componentProps: {
                    details: details.value
                },
            })

            modal.onDidDismiss().then(({data}) => {
                if (data && data.success) // popover should return true if verification is successful
                {
                    console.log('from modal', data.amount, data.msg)
                    
                    fAPI.chargeOrder(OID, data.amount, data.msg).then(()=>{
                        //console.log('getting this back', response.data)
                        fUI.toasty('Payment creation success!')
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                        }
                    })
                    .finally(() => {
                        operating.value = false
                    })
                }
                else
                    operating.value = true
            })
            
            await modal.present();
            operating.value = false
        }

        return {
            init,
            operating,
            userInit,
            details,
            showDetails,
            orderItems,
            readyByText,
            load,
            decline,
            refund,
            charge,
            showDeclineBtn,
        }
    },
}
</script>