<template>
    <div class="relative flex items-center">
        <button v-if="canCopy" type="button" @click="copyInfo(content)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transform hover:-rotate-6 transition-transform duration-300 ease-in-out" :class="popover && '-rotate-6 text-cerulean-blue'" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
            </svg>
        </button>
        <div class="absolute inset-x-0 bottom-full mb-1.5 flex justify-center transition-opacity duration-500 ease-in-out" :class="popover ? 'opacity-100' : 'opacity-0'">
            <span class="bg-gray-900 text-white rounded-md text-xs leading-4 tracking-wide font-semibold uppercase py-1 px-3 filter drop-shadow-md">Copied!</span>
        </div>
    </div>
    
</template>

<script lang="ts">
import { ref } from 'vue'
export default {
    name: 'CopyButton',
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    setup() {
        const popover = ref(false)
        const canCopy = !!window.navigator.clipboard
        const copyInfo = (info: string) => {
            window.navigator.clipboard.writeText(info)
            popover.value = true
            setTimeout(() => {
                popover.value = false
            }, 2500);
        }

        return {
            canCopy,
            popover,
            copyInfo,
        }
    },
}
</script>