
import { ref } from 'vue'
import fUI from '../integration/foodexUI'
import fAPI from '../integration/foodexAPI'
import CopyButton from './CopyButton.vue'
import DeclineModal from '../modals/DeclineModal.vue'
import RefundModal from '../modals/RefundModal.vue'
import InvoiceModal from '../modals/InvoiceModal.vue'
import { AxiosError } from 'axios'
import moment from 'moment'

export default {
    name: 'OrderDetails',
    components: {
        CopyButton,
    },
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    setup(props: any, context: any) {
        const init = ref(false)
        const operating = ref(false)

        const showDeclineBtn = ref(props.status == 'OrderConfirmed')

        let OID = ''
        const details = ref()
        const orderItems = ref([])
        const showDetails = ref(false)
        const userInit = ref(false)
        const readyByText = ref('')

        const load = (orderID: any) => {
            if (!init.value)
            {
                OID = orderID
                fAPI.getOrderReceipt(orderID).then((response)=>{
                    console.log('getting this back', response.data)
                    details.value = response.data
                    orderItems.value = JSON.parse(response.data.receiptContent)
                    readyByText.value = moment(response.data.readyBy).format('h:mm A')
                })
                .catch((authError: AxiosError) => {
                    if (authError && authError.response)
                    {
                        console.log('Operation failed', authError.response.status)
                        console.log('Error', authError.response)
                    }
                })
                .finally(() => {
                    init.value = true
                })
            }
        }

        const decline = async () => {
            operating.value = true

            const modal = await fUI.createModal({
                id: fUI.ID.DeclineModal,
                component: DeclineModal,
                componentProps: {
                    details: details.value
                },
            })

            modal.onDidDismiss().then(({data}) => {
                if (data && data.success) // popover should return true if verification is successful
                {
                    console.log('from modal', data.reason)
                    context.emit('statusChange')
                    showDeclineBtn.value = false
                    fAPI.declineOrder(OID, data.reason).then(()=>{
                        //console.log('getting this back', response.data)
                        fUI.toasty('Decline success!')
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                        }
                    })
                    .finally(() => {
                        operating.value = false
                    })
                }
                else
                    operating.value = true
            })

            await modal.present();
        }

        const refund = async () => {
            operating.value = true
            const modal = await fUI.createModal({
                    id: fUI.ID.RefundModal,
                    component: RefundModal,
                    componentProps: {
                        details: details.value
                    },
                })

            modal.onDidDismiss().then(({data}) => {
                if (data && data.success) // popover should return true if verification is successful
                {
                    //console.log('from modal', data.amount, data.msg, data.source)
                    fAPI.refundOrder(OID, data.amount, data.msg, data.source).then(()=>{
                        //console.log('getting this back', response.data)
                        fUI.toasty('Refund success!')
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                        }
                    })
                    .finally(() => {
                        operating.value = false
                    })
                }
                else
                    operating.value = true
            });
            
            await modal.present();
            operating.value = false
        }

        const charge = async () => {
            operating.value = true
            const modal = await fUI.createModal({
                id: fUI.ID.InvoiceModal,
                component: InvoiceModal,
                componentProps: {
                    details: details.value
                },
            })

            modal.onDidDismiss().then(({data}) => {
                if (data && data.success) // popover should return true if verification is successful
                {
                    console.log('from modal', data.amount, data.msg)
                    
                    fAPI.chargeOrder(OID, data.amount, data.msg).then(()=>{
                        //console.log('getting this back', response.data)
                        fUI.toasty('Payment creation success!')
                    })
                    .catch((authError: AxiosError) => {
                        if (authError && authError.response)
                        {
                            console.log('Operation failed', authError.response.status)
                            console.log('Error', authError.response)
                        }
                    })
                    .finally(() => {
                        operating.value = false
                    })
                }
                else
                    operating.value = true
            })
            
            await modal.present();
            operating.value = false
        }

        return {
            init,
            operating,
            userInit,
            details,
            showDetails,
            orderItems,
            readyByText,
            load,
            decline,
            refund,
            charge,
            showDeclineBtn,
        }
    },
}
