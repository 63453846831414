<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Decline Order</ion-title>
      <ion-buttons slot="end">
            <button type="button" @click="onDismiss()" class="px-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="p-5 grid gap-6 grid-cols-1">
        <div>
            <label class="fdx-ctrl-label">Enter a reason for declining this order</label>
            <input type="text" class="fdx-form-ctrl" placeholder="Reason to decline" v-model="reason" />
        </div>

        <div>
            <h2 class="text-rich-black-fogra-29 font-bold text-xl mb-4">Preview</h2>
            <div class="flex justify-between items-start">
                <div class="hidden sm:flex items-center mr-4">
                    <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>
                </div>
                <div class="flex-grow w-full p-4 bg-cultured rounded-standard">
                    Hi {{details.customerName.split(" ")[0]}}, Unfortunately {{details.shop.name}} cannot fulfill your order #{{details.orderShortID}} due to <span v-if="reason" class="bg-yellow-orange text-white px-1">{{ reason + '.'}}</span> Sorry about that. A full refund has been processed and will return to your card in 3-5 business days. Thank you for choosing FOODEX.
                </div>
            </div>
        </div>
    </div>
  </ion-content>
  <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
            <button :disabled="!reason" class="btn btn-primary m-4 uppercase" @click="onSucces()">Submit</button>
        </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import fUI from '../integration/foodexUI'

export default defineComponent({
    name: fUI.ID.DeclineModal,
    components: { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, },
    props: {
        details: { type: Object, required: true },
    },
    setup() {
        const reason = ref('temporarily shutdown')

        const onSucces = () => {
            fUI.modalSuccess(fUI.ID.DeclineModal, {
                success: true,
                reason: reason.value
            })
        }

        return {
            reason,
            onDismiss: () => fUI.modalCancel(fUI.ID.DeclineModal),
            onSucces,
        }
    },
})
</script>