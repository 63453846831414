<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Refund Order</ion-title>
      <ion-buttons slot="end">
            <button type="button" @click="onDismiss()" class="px-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="p-5 grid gap-4 grid-cols-1">
        <div>
            <label class="fdx-ctrl-label">Refund Amount (max {{$filters.currency(details.grandTotal)}})</label>
            <div class="mt-1 relative flex items-center">
                <input type="number" class="fdx-form-ctrl" @focus="$event.target.select()" :value="amount" @blur="updateValue" v-on:keyup.enter="$event.target.blur()" />
                <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 mr-5">
                    <kbd class="inline-flex items-center rounded px-2 text-sm font-sans font-medium text-gray-400">
                    CAD
                    </kbd>
                </div>
            </div>
        </div>

        <div>
            <div class="flex justify-between">
                <label class="fdx-ctrl-label">Additional Details</label>
                <label class="fdx-ctrl-label">{{msg.length}}/64</label>
            </div>
            
            <input type="text" class="fdx-form-ctrl" placeholder="optional" v-model="msg" maxlength="64" />
        </div>

        <div>
            <label class="fdx-ctrl-label">Source of Refund</label>
            <div class="grid gap-4 grid-cols-2 mt-2">
                <div :class="source === 'FOODEX' && 'ring-2'" class="w-full py-4 px-2 rounded-standard border border-solid border-cultured ring-peach cursor-pointer text-center" @click="source = 'FOODEX'">FOODEX</div>
                <div :class="source !== 'FOODEX' && 'ring-2'" class="w-full py-4 px-2 rounded-standard border border-solid border-cultured ring-peach cursor-pointer text-center" @click="source = 'MERCHANT'">Merchant</div>
            </div>
        </div>

        <div>
            <h2 class="text-rich-black-fogra-29 font-bold text-xl mb-4">Preview</h2>
            <div class="flex justify-between items-start">
                <div class="hidden sm:flex items-center mr-4">
                    <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>
                </div>
                <div class="flex-grow w-full p-4 bg-cultured rounded-standard">
                    Hi {{details.customerName.split(" ")[0]}}, <span v-if="msg" class="bg-cerulean-blue text-white px-1">{{msg}}</span> A refund of <span class="bg-yellow-orange text-white px-1">{{$filters.currency(amount)}}</span> from your order #{{details.orderShortID}} has been processed and will return to your card in 3-5 business days. Thank you for choosing FOODEX.
                </div>
            </div>
        </div>
    </div>
  </ion-content>
  <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
            <button :disabled="!amount" class="btn btn-primary m-4 uppercase" @click="onSucces()">Submit</button>
        </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import fUI from '../integration/foodexUI'

export default defineComponent({
    name: fUI.ID.RefundModal,
    components: { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, },
    props: {
        details: { type: Object, required: true },
    },
    setup(props: any) {
        const amount = ref(0)
        const source = ref('FOODEX')
        const msg = ref('')

        const onSucces = () => {
            fUI.modalSuccess(fUI.ID.RefundModal, {
                success: true,
                    amount: amount.value,
                    msg: msg.value,
                    source: source.value,
            })
        }

        const updateValue = (event: any) => {
            const value = event.target.value
            amount.value = 0
            if (value)
            {
                amount.value = +value >= props.details.grandTotal ? props.details.grandTotal : (+value).toFixed(2)
            }
        }
        
        return {
            amount,
            msg,
            source,
            onDismiss: () => fUI.modalCancel(fUI.ID.RefundModal),
            onSucces,
            updateValue,
        }
    },
})
</script>